import React, { useState } from 'react';
import logo from '../../assets/logo.png';
import './index.css';
import axios from '../api';

function Login(){
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }
      const handleChange = (event) => {
        if (!isValidEmail(event.target.value)) {
          setError("Email inválido");
        } else {
          setError(null);
        }
        setEmail(event.target.value);
      }
      
    
    const handleLogin = async () => {
        try {
            const response = await axios.post('/login', { email, senha });
            console.log(response.data);
        } catch (error) {

            console.log(error);

            if (!error?.response){
                setError('Erro ao acessar o servidor');
            }
            else if (error.response.status === 400){
                setError('Login ou senha inválida');
            }
        }
    }
    
    return(
        <div className="login-box">
            <img src= {logo} alt="logo"/>
            <form>
                <label htmlFor="username">E-mail:</label>
                <input type="text"
                id="username"
                className="username"
                placeholder="Digite o seu E-mail"
                required
                onChange={handleChange}
                />
                <label htmlFor="password">Senha:</label>
                <input type="password"
                id="password"
                className="password"
                placeholder="Digite sua senha"
                required
                onChange={(e) => setSenha(e.target.value)}
                />
                <button 
                type="button" 
                value="Entrar"
                onClick={handleLogin}>Login</button>
            </form>
            <p type="erro">{error}</p>
        </div>
        )}
    
export default Login;